.contractlist {
  background-color: #f0f2f5;
}
.contractlist-title {
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  line-height: 60px;
  height: 60px;
  padding-left: 24px;
}
.contractlist-content {
  margin: 24px;
}

@media screen and (max-width: 768px) {
  .contractlist-content-search {
    background-color: white;
    padding: 26px 10px;
  }
  .ant-input-affix-wrapper {
    max-width: 100% !important;
    margin-bottom: 6px;
  }
}
@media screen and (min-width: 769px) {
  .contractlist-content-search {
    background-color: white;
    padding: 26px 60px;
  }
}
.contractlist-content-list {
  background-color: white;
  margin-top: 24px;
  padding: 10px;
}
.contractlist-content-search-input {
  max-width: 60%;
  width: 200px;
  margin-right: 3px;
}

.contractlist-content-search-form1 {
  margin-left: 4px;
  transition: all 0.3s;
  display: inline;
}
.contractlist-content-search-form2 {
  display: none;
}
