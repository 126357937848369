#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout2 .site-layout-background {
  background: #fff;
}

.trigger {
  color: white !important;
  font-size: 16px !important;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.trigger2 {
  color: white !important;
  font-size: 16px !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}

.top-shadow {
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  height: 48px !important;
}
.ant-layout-sider-collapsed {
  min-width: 48px !important;
  max-width: 48px !important;
  width: 48px !important;
}
.logo {
  color: white;
  font-size: 15px;
  line-height: 48px;
  text-align: center;
  height: 48px;
  padding-top: 2px;
  font-weight: bold;
}
.logo2 {
  display: none;
}
.home-logo-img {
  width: 40px;
  margin-right: 6px;
}
.home-logo-title {
}
.ant-layout-content {
  margin-top: 0px !important;
}
.site-layout-dropdown {
  float: right;
  width: 140px;
  padding-right: 20px;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
}
.home-sider {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
}
.site-layout {
  margin-left: 200px;
  transition: all 0.3s;
}
.site-layout2 {
  margin-left: 40px;
  transition: all 0.3s;
}
