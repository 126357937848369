.login{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: #f0f2f5;
    background-image: url(/src/assets/img/login_bg.svg);
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
}
.login-box{
    width: 328px;
    max-width:100% ;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
}
.login-box-title{
    position: relative;
    top: 2px;
    color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 33px;
    font-family: Avenir,helvetica neue,Arial,Helvetica,sans-serif;
    text-align: center;
    margin-bottom: 40px;
}
.login-box-id{
    margin-bottom: 24px;
}
.login-box-pw{
    margin-bottom: 24px; 
}