* {
  box-sizing: border-box;
}
html {
  -webkit-text-size-adjust: none;
}
a {
  text-decoration: none;
  color: black;
}
.flex-middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-between2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.clear-both {
  clear: both;
}