.chat{
    margin: 0;
    padding: 0;
    background-size: 1180px;
    font-family: Georgia,"Times New Roman" , "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
}
.chat-top{
    width: 100%;
    text-align: center;
    height: 50px;
    background-color: black;
    color: white;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
}
.chat-box{
    width: 100%;
    height: 350px;
    border: 1px solid black;
    font-size: 20px;
    margin: 0;
    padding: 0;
    overflow-y:scroll;
    text-align: left;
    resize: none;
}
.chat-name{
    width: 100%;
    height: 40px;
    border: 1px solid black;
    font-size: 20px;
}
.chat-input{
    width: 100%;
    height: 40px;
    border: 1px solid black;
    font-size: 20px;
}
.chat-button{
    width: 100%;
    text-align: center;
    height: 40px;
    background-color: black;
    color: white;
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
}
.chat-delete{
    width: 100%;
    text-align: center;
    height: 40px;
    background-color: black;
    color: white;
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
}