.contractcontent{
  background-color: #f0f2f5;
}
.contractcontent-title{
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  line-height: 60px;
  height: 60px;
  padding-left: 24px;
}
.contractcontent-back{
  font-size: 14px;
  color: rgb(24, 144, 255);
  font-weight: normal;
  float: right;
  margin-right: 24px;
  cursor: pointer;
}
.contractcontent-content{
  margin: 24px;
}
.contractcontent-content-search{
  background-color: white;
  padding: 26px 20px;
  margin-bottom: 1px;
}
.contractcontent-content-search-title{
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}
@media screen and (max-width:768px){
  .contractcontent-content-search-list{
    font-size: 14px;
    width: 100%;
    float: left;
    margin: 10px 6px;
    overflow: hidden;
  }
}
@media screen and (min-width:769px){
  .contractcontent-content-search-list{
    font-size: 14px;
    width: 31.33%;
    float: left;
    margin: 10px 1%;
  }
}

.pdf-con{
  padding: 60px;
}
.pdf-con div{
  font-size: 12px;
}
.pdf-con .blank{
  height: 140px;
  width: 100%;
}

.contractcontent-content-tablet{
  display: inline;
}

.contractcontent-content-tablet2{
  display: none;
}