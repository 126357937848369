.systemoption{
    background-color: #f0f2f5;
}
.systemoption-title{
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    line-height: 60px;
    height: 60px;
    padding-left: 24px;
}
.systemoption-box{
    width: 100%;
    padding: 24px;
}
.systemoption-content{
    width: 100%;
    max-width: 1300px;
    background-color: white;
    padding: 24px;
    margin: auto;
}

.systemoption-content-top{
    width: 100%;
}

.systemoption-content-title{
    font-size: 20px;
    border: #1677ff solid 3px;
    border-radius: 15px;
    width: 48%;
    margin-right: 4%;
    padding: 20px;
    float: left;
    margin-bottom: 10px;
}

.systemoption-content-title2{
    float: left;
    width: 48%;
    padding: 0 0 20px 20px;
}

.systemoption-content-title3{
    font-size: 20px;
}

.systemoption-content-title2-bill{
    background-color: #1677ff;
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

.systemoption-content-item{
    margin-top: 20px;
}

.systemoption-input1{
    margin-top: 5px;
    width: 100%;
}

.css-dev-only-do-not-override-byeoj0{
    margin-bottom: 2px !important;
}

.systemoption-create{
    width:100%;
    height:40px;
    font-size:18px;
    font-weight: bold;
    margin-top: 20px;
}

.ant-form-item-control-input-content{
    width: 18% !important;
}

.systemoption-content-bottm{
    width: 100%;
}
.systemoption-content-bottm-left{
    float: left;
    width: 32%;
    margin-right: 2%;
    padding-top: 24px;
}
.systemoption-content-bottm-middle{
    float: left;
    width: 32%;
    margin-right: 2%;
    padding: 20px 0 29px 20px;
}
.systemoption-content-bottm-right{
    float: left;
    width: 32%;
    padding: 20px 0 29px 20px;
}

.input-title{
    width: 65px;
}

.systemoption-content-contents{
    margin-bottom: 1px !important;
}

.systemoption-content-content{
    width: 16%;
    float: left;
}

.systemoption-content-content-button{
    width: 99%;
}

.systemoption-content-content-button-s{
    pointer-events:none;
}

.systemoption-content-menus{
    width: 100%;
}

.systemoption-content-menu{
    width: 16%;
    float: left;
    padding-left: 7px;
    margin-bottom: 3px;
}